
import { caasDeepClone, caasLocalInfo } from "@platform/common";
// app 名称的集合
export enum AppNameMap {
  SSO = "sso",
  P401 = "page-401",
  P404 = "page-404",
  USAPP = "us-legislation",
  SHELLNAME = "us_legislation_cms_app",
}

export const AppBaseRouter = {
  usLegislation: `${process.env.BASE_URL}legislation`,
}

export const _userInfo = new caasLocalInfo({ name: "userInfo" });

export function getUserData(cookieData: any) {
  const cookieObj = cookieData;
  const userInfo = {
    "region": "US",
    "user":{
      "id": "",
      "username": "",
      "email": "",
      "ldapuser_principal_name": "",
      "user_source": "",
    },
    "group_list":[],
    "role_list": "",
    "permission_list": "",
    "token": "",
    "refresh_token": ""
  }
  const _ui = caasDeepClone(userInfo);
  if(cookieObj && cookieObj.token) {
    _ui.user.id = cookieObj.user_id;
    _ui.user.email = cookieObj.user_email;
    _ui.user.username = cookieObj.user_username;
    _ui.user.user_source = cookieObj.user_user_source;
    _ui.user.ldapuser_principal_name = cookieObj.user_ldapuser_principal_name;
    _ui.region = cookieObj.region;
    _ui.token = cookieObj.token;
    _ui.refresh_token = cookieObj.refresh_token;
  }
  return _ui;
}


export function getUrlQuery(url: string) {
  let obj = {};
  if(url.indexOf('?') != -1){
    let arr = url.slice(url.indexOf('?')+1).split('&');
    arr.forEach((item: string) => {
      let param = item.split('=');
      obj[param[0]] = param[1];
    })
  }
  return obj;
}