import routes from "./router";
import { AppNameMap } from "@/utils";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL)
})
const SHELLNAME = AppNameMap.SHELLNAME;
router.beforeEach((to, from, next) => {
  const title = to.meta.title;
  document.title = title ? `${title} - ${SHELLNAME}` : SHELLNAME;
  next();
})

export default router;