import { AppNameMap } from "@/utils";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  { 
    path: "/",
    redirect: "/legislation"
  },
  {
    path: "/sso",
    name: AppNameMap.SSO,
    component: () => import("@/views/sso/index.vue")
  },
  {
    path: "/legislation/:page*",
    name: AppNameMap.USAPP,
    component: () => import("@/views/us-app/index.vue")
  },
  {
    path: "/401",
    name: AppNameMap.P401,
    component: () => import("@/views/error/401.vue"),
    meta: { title: "401" }
  },
  {
    path: "/404",
    name: AppNameMap.P404,
    component: () => import("@/views/error/404.vue"),
    meta: { title: "404" }
  },
  {
    path: "/:pathMatch(.*)",
    redirect: { name: AppNameMap.P404 }
  }
]

export default routes;